<template>
  <div class="lookgoodListInfo">
     <my-header></my-header>
      
      <div class="box">
        <el-card>
        <el-table :data="goodListInfo" border stripe class="table" >
         <el-table-column type="index"></el-table-column>
         <el-table-column label="姓名" prop="name" align="center" width="100"></el-table-column>
         <el-table-column label="联系电话" prop="phone" align="center" width="200"></el-table-column>
         <el-table-column label="清单预算" prop="budget" align="center" width="100"></el-table-column>
         <el-table-column label="备注" prop="message" align="center" width="80"></el-table-column>
         <el-table-column label="状态" prop="state" align="center" width="80" 
         :formatter="formatRole" class="table-state"
          >
         </el-table-column>
         <el-table-column label="创建时间" prop="createTime" width="180"></el-table-column>
         <el-table-column label="清单方案附件下载" width="250" align="center">
          <template slot-scope="scope" v-if="scope.row.serviceAffix">
             <el-button slot="reference" type="primary" size="mini" @click="downLoadUrl(scope.row.serviceAffix,'清单方案附件')">
              清单方案附件
            </el-button>
          </template>
        </el-table-column>
         <el-table-column label="购买清单附件下载" prop="affix" width="180">
            <template slot-scope="scope" v-if="scope.row.affix">
            <el-button slot="reference" type="primary" size="mini" @click="downLoadUrl(scope.row.affix,'购买清单附件')">
              购买清单附件
            </el-button>
            </template>

         </el-table-column>
         <el-table-column fixed="right" label="操作" width="200" align="center">
           <template slot-scope="scope">
             <!-- 驳回 -->
             <el-button slot="reference"  @click="showUserState(scope.$index,scope.row)"  type="warning" size="small"  
              v-if="scope.row.state==2" >驳回</el-button> 
               <!-- 确认 -->
              <el-button slot="reference" type="info" size="small" @click="confirmStateInfo(scope.$index,scope.row)"
             v-if="scope.row.state==2" >确认</el-button>
           </template>
         </el-table-column>
        </el-table>
         <!-- 弹出驳回框  -->
           <el-dialog title="驳回" :visible.sync="setDialogVisible" @close="editDialogClosed">
          <el-form :model="form" ref="editFormRef" label-width="300" > 
          <el-form-item label="备注" prop="message" >
           <el-input v-model="form.message"  autocomplete="off" placeholder="请填写您的备注信息"></el-input>
           </el-form-item>
           </el-form>
           <div slot="footer" class="dialog-footer">
           <el-button @click="setDialogVisible= false">取 消</el-button>
           <el-button type="primary"  @click=setUserState :disabled="this.form.message=='' ">确 定</el-button>
           </div>
           </el-dialog> 
        <!-- 分页 -->
       <el-pagination   
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="sizes, prev, pager, next"
          :total="total">
       </el-pagination>
        </el-card> 
      </div>

  </div>
</template>
<script>
export default {
  data(){
    return{
      goodListInfo:[{
         name:"",
         phone:"",
        budget:"",
        State:"",
       serviceAffix:"",
       affix:"",
       id:""
        }],
        params:{
         pageSize: 10, // pageSize代表每页显示的数目
         pageNum:1,
         id:"",
         userId: sessionStorage.getItem("userId"),
        state:""
        },
        form:{
          message:'',
          state:""  //模拟有下拉选项
        },
        //控制修改驳回对话框的显示隐藏
         setDialogVisible: false,
         formLabelWidth: '120px',
        total: 8,    // total列表的总数
        id:'',
        i:''
        }
  },
  //获取购物清单列表
  mounted(){
    this.get()
  },
  watch:{
  },
  methods:{
    
    async get(){
      let url=`front/shoppingList/findList?pageNum=${this.params.pageNum}&pageSize=${this.params.pageSize}&userId=${this.params.userId}`,
      res=await this.$http({
        url:url,
        method:"get"
      })
      console.log(res)
      if(res.data.status!==200) return this.$message(res.data.msg)
      this.goodListInfo=res.data.data.list;
      this.total=res.data.data.total
    },
    // pageSize 改变时会触发的事件
     handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
              this.params.pageSize = val
              this.get()
             
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
             this.params.currentPage = val;
             this.get()
             
      },
      //待用户确认
      async confirmStateInfo(index,item){
       console.log(index,item)
         const res=await this.$http({
         method: "post",
          url: `front/shoppingList/changeState`,
          data:{
            id:item.id,
            userId:item.userId,
            state:3
          }
     })  
      alert(`确认成功！`)
      this.get()
  },
    //根据状态值显示状态
     formatRole: function( row, column) {
                 return row.state == '1' ? "审核中" : row.state == '2' ? "待用户确认" : "已完成";
         }, 
  
  //监听修改对话框的关闭事件
  editDialogClosed(){
    this.$refs.editFormRef.resetFields()
  },
  //驳回状态对话框弹出按钮
   showUserState(index,item,i){
    this.setDialogVisible =true
    this.id=item.id;
    this.index=index;
    this.i=i;
    },
  
 //提交备注信息并发请求
 async setUserState(){
   this.setDialogVisible=false
    // console.log(`当前索引${this.index},当前id：${this.id},当前备注信息：${this.form.message}`)
    //  if(this.form.message==''){
    // this.$message(`请填写您的驳回备注信息！`) 
    // return;
    // }else{
    //    const res=await this.$http({
    //       method:'post',
    //       url: 'front/shoppingList/changeState',
    //       data:{
    //         index:this.index,
    //         id:this.id,
    //         state:1,
    //         message:this.form.message,
    //         // serviceAffix:this.serviceAffix
    //       }
    //    })
    //    console.log(res)
    //    if(res.status!==200){
    //      this.$message(`驳回失败，请重试！`)
    //    }else{
    //      this.$message(`驳回成功！`)
    //    }
    //    this.get();

    // }
      
 

},
//下载附件清单
 // 点击下载文件
    downLoadUrl(url, name) {
      const a = document.createElement("a");
      // 这里是将url转成blob地址，
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob);
          a.download = name; // 下载文件的名字
          document.body.appendChild(a);
          a.click();
        });
    }
},
}

</script>
<style lang="scss"  scoped>
.lookgoodListInfo>.box{
  width: 100%;
  margin-top: 150px;
}
.lookgoodListInfo .el-pagination{
  padding: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}





</style>