<template>
  <div @click="userListshow = false">
    <my-header></my-header>
    <div class="top-title">
      <span>业务表</span>
    </div>
    
    <!-- 已报名业务 -->

    <el-card class="box-card" v-if="userbusinessshow">
      <div :style="{ fontSize: '20px', padding: '15px', color: '#666' }">
        <span @click="$router.push('/index')" :style="{ cursor: 'pointer' }"
          >返回</span
        >
        
          <el-button v-if="roleId == 3" @click="$router.push('./private')" type="primary" :style="{ float: 'right', marginBottom:'10px'}">添加私有业务</el-button>
      </div>
       <div :style="{fontSize: '20px', padding: '15px', color: '#666'}">已报名业务</div>
      <el-table :data="userbusinessList" border style="width: 100%" >
        <el-table-column prop="title" label="标题" width="250">
        </el-table-column>
        <el-table-column label="采购类型" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.purchaseType | purchaseType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="180" label="业务所在地址">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column width="80" prop="applyNum" label="报名人数">
        </el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="userListactive(scope.$index, scope.row)"
              >报名人员明细</el-button
            >
            <el-button size="mini" @click="lookInfo(scope.$index, scope.row)"
              >跟进详情</el-button
            >
            <el-button
              size="mini"
              @click="delectbusiness(scope.$index, scope.row)"
              type="danger"
              >删除</el-button
            >
          </template>
       
        </el-table-column>
        
      </el-table>
     
       <el-pagination
       v-if="roleId == 3"
        class="el-pagination"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="currentchange1"
        :hide-on-single-page="true"
      >
      </el-pagination>
      <!-- 分页 -->
     
      <!-- 分页 -->
    </el-card>

    <!-- 报名人员明细 -->
    <div @click.stop class="userList" v-if="userListshow">
      <el-card class="box-card-userList">
        <div>
          <span
            :style="{ fontSize: '22px', marginLeft: '300px' }"
            class="el-icon-circle-close"
            @click="userListshow = false"
          ></span>
        </div>
        <div class="userList-item" v-for="(v, k) of userList" :key="k">
          <div>
            <span>昵称:</span>
            <span v-text="v.nickName ? v.nickName : '未填写'"></span>
          </div>
          <div>
            <span>地区:</span>
            <span
              v-text="
                v.region
                  ? `${v.region[0]}${v.region[1]}${v.region[2]} `
                  : '未填写'
              "
            ></span>
          </div>
          <div>
            <span>手机:</span>
            <span v-text="v.phone ? v.phone : '未填写'"></span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      // 业务类型
      optionsTwo: [
        {
          value: "0",
          label: "查询全部",
        },
        {
          value: "1",
          label: "厨房设备采购",
        },
        {
          value: "2",
          label: "厨房服务采购",
        },
        {
          value: "3",
          label: "食材采购",
        },
      ],
      // 地区
      options: JSON.parse(sessionStorage.getItem("allAreaData")),
      // 报名人员明细显示隐藏
      userListshow: false,
      // 报名人员明细
      userList: [],
      // 业务表列表
      businessList: [],
      //   已报名业务列表
      userbusinessList: [],
      //   已报名业务的显示隐藏
      userbusinessshow: false,
      //获取用户信息
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      // 搜索的标题
      searchtitle: "",
      state:"0",
      pageNum: 1,
      pageSize: 5,
      total: 0,
      // 类型选择
      typevalue: "",
      // 采购类型选择
      purchaseType: "",

      userId:"",
      ruleForm: {
        entryName: "",
        address: "",
        businessContent: "",
      },
      rules: {
        entryName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入业务所在地址", trigger: "blur" },
        ],
        businessContent: [
          { required: true, message: "请输入业务内容", trigger: "blur" },
        ],
      },
    };
  },
  filters: {
    purchaseType(val) {
      if (val == 1) {
        return "厨房设备采购";
      } else if (val == 2) {
        return "厨房服务采购";
      } else if (val == 3) {
        return "食材采购";
      } else if (val == 4) {
        return '私有业务'
      } else {
        return "未填写";
      }
    },
  },
  computed:{
    roleId() {
      return sessionStorage.getItem("roleId");
    },
  },
  methods: {
    // async findBusinessFindAll(){
    //   const res = await this.$http({
    //     method: 'get',
    //     url:`front/businessInfo/findAll?sortType=create_time desc`,
    //     params:{
    //       userId:this.userinfo.id,
    //       purchaseType:4,
        
    //     }
    //   });
    //   console.log(res.data);
    //   if(res.data.status == 200){
    //     this.findBusinessFindAll = true;
    //     this.userbusinessList = res.data.data.list;
    //     this.pageNum = 1;
    //     this.total = res.data.data.total;
    //   }
    // },
    // 获取已报名业务
    async findBusinessByUserId() {
      const res = await this.$http({
        method: "get",
        url: `front/businessInfo/findBusinessByUserId`,
        params: {
          userId: this.userinfo.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sortType: "create_time desc",
        },
      });
        console.log(res.data.data.list)
      if (res.data.data.list) {
        this.userbusinessshow = true;  
        this.userbusinessList = res.data.data.list;
        this.pageNum = 1;
        
      } else {
        this.$message.error("没有已报名业务");
      }
    }, 
    // 查看详细信息
    async lookInfo(index, row) {
      console.log(row,index);
      this.$router.push({
        path: "/wallBulletinItem",
        query: {
          item: JSON.stringify(this.userbusinessList[index]),
          itemA: JSON.stringify(this.businessList[index]),
        },
      });
    },
    //删除已报名业务
    async delectbusiness(index, row) {
      console.log(row);
      const res = await this.$http({
        method: "post",
        url: `front/businessUser/delete`,
        data: {
          id: row.id,
        },
      });
      console.log(res.data)
      if (res.data.status == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.userbusinessList.splice(index, 1);
      } else {
        this.$message.error("删除失败");
      }
    },
      //获取业务表列表
    async getbusiness() {
      let purchaseType = "";
      if (this.purchaseType == "" || this.purchaseType == 0) {
        purchaseType = "";
      } else {
        purchaseType = `&purchaseType=${this.purchaseType}`;
      }
      const res = await this.$http({
        methos: "get",
        url: `front/businessInfo/findAll?&state=${this.state}&sortType=create_time desc`,
        params:{
          userId:this.userinfo.id,
          purchaseType: 4,
          pageNum:this.pageNum,
          pageSize:this.pageSize
        }
      });
      
      this.businessList = [];
      console.log(res.data)
      if (res.data.status == 200) {
        this.pageNum = 1;
        res.data.data.list.map((v, k) => {
          this.businessList.push(v);
        });
        this.total = res.data.data.total;
      }
    },
    // 显示报名人员明细
    async userListactive(k, v) {
      console.log(v);
      const res = await this.$http({
        method: "get",
        url: `front/businessInfo/findUserNumById?pageNum=1&pageSize=5&sortType=create_time desc&businessId=${v.id}`,
      });
      console.log(res.data.data.list);
      console.log(res.data.status);
      if (res.data.status == 200) {
        this.userListshow = true;
        this.userList = res.data.data.list;
        console.log(this.userList);
      } else {
        this.$message("目前还没有报名人员");
      }
    },
    // 页面改变时的回调函数
    currentchange1(p) {
      this.pageNum = p;
      if (this.userbusinessshow) {
        this.getbusiness();
      } else {
        this.findBusinessByUserId();

      }
    },
    
  },

  created() {
    this.findBusinessByUserId();
    // this.findBusinessFindAll();
    this.getbusiness();
  },
};
</script>
<style lang="scss" scoped>
.box{margin-top: 50px;}
.el-dialog__wrapper {
  & ::v-deep .el-dialog {
    min-width: 1000px;
  }
}
.top-title {
  background: #f5f5f6;
  height: 150px;
  margin: 0 auto;
  font-size: 36px;
  color: #101d37;
  font-weight: 700;
  text-align: center;
  line-height: 120px;
  position: relative;
  color: #101d37 !important;
  margin-top: 140px;
}
.box-card {
  margin: 0 auto;

  margin-bottom: 50px;
  width: 75%;
  min-width: 1000px;
}
.el-dialog__footer {
  text-align: start;
}
.input-with-select {
  width: 260px;
  margin-left: 20px;
}
.button-box {
  margin-top: 15px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 40px;
}
.button-item {
  margin-left: 20px;
}
.userList {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -150px;
}
.userList-item {
  padding: 5px 5px;
  padding-bottom: 20px;
}
.userList-item:not(:last-child) {
  border-bottom: 1px solid #aaa;
}
.box-card-userList {
  height: 100%;
  overflow: auto;
}
.box-card-userList > div {
  height: 100%;
}
.el-pagination {
  padding: 30px;
  margin-left: 660px;
}
.el-dialog__body > div {
  padding: 3px;
}
</style>