<template>
   <div>
       <my-header></my-header>
       <div class="box">
           <div class="box_index">
                <div class="index_message" >
                    <el-tabs
                        :stretch="true"
                        class="order-nav-top"
                        v-model="activeName"
                        @tab-click="tabClick" 
                        >
                        <el-tab-pane label="待授权" name="0" >
                            <div v-if="isShow">
                                <div class="message"  v-for="(v,k) in companyList" :key="k">
                                    <div class="message_A">
                                        <div class="font">
                                            合作公司
                                        </div>
                                        <span>{{v.parentCompanyName}}</span>
                                    </div>
                                    <div class="message_A">
                                        <div class="font">
                                            申请时间
                                        </div>
                                        <span>{{v.updateTime}}</span>
                                    </div>
                                    <div class="message_A">
                                        <div class="font">
                                            授权状态
                                        </div>
                                        <span>{{v.state | state}}</span>
                                    </div>
                                    <div class="font_A">
                                            <span class="button1" @click="consent(v.id)">同意</span>
                                            <span class="button1" @click="reject(v.id)">拒绝</span>
                                    </div>
                                    <div class="message_A">
                                        <div class="button" @click="store(v.parentCompanyId)">查看店铺商品</div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="company-box-2">
                                <div class="company-box-item" v-for="(v, k) of companyItem" :key="k">
                                    <div class="company-box-item-img">
                                    <img :src="v.companyLogo" alt="" />
                                    </div>
                                    <div class="company-box-item-name">
                                    <span>{{ v.companyName }}</span>
                                    </div>
                                    <div class="company-box-item-button">
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="goodlist-list" v-if="companyListItem[0]">
                            <div
                              class="goodlist-list-box"
                              v-for="(item, index8) in companyListItem"
                              :key="index8"
                            >
                              <div class="goodlist-list-box-box" @click="toMessage(item)">
                                <div class="goodlist-include-box">
                                  <img class="goodlist-list-img" :src="item.mainImage" alt="" />
                                </div>
                                <p class="goodlist-list-txt">{{ item.name }}</p>
                                <div class="price-row">
                                  <span class="price-a">￥</span>
                                  <span class="price-b">{{ item.price }}</span>
                                  <span class="price-a">.00</span>
                                </div>
                                <div class="goodlist-bottom">
                                  <div class="goodlist-bottom-left" @click.stop="toMessage(item)">
                                    <span>查看详情</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <el-card
                              class="box-card"
                              v-if="!isShow"
                              style="margin-bottom: 30px"
                              shadow="never"
                            >
                              <div class="text item">对不起,您所浏览的商品不存在。</div>
                            </el-card>
                          </div>
                          <div class="pagin-box-item" v-if="companyListItem[0]">
                            <el-pagination
                              background
                              layout="prev, pager, next"
                              :total="total"
                              :page-size="pageSize"
                              @current-change="itemhandleCurrentChange"
                              :hide-on-single-page="true"
                            >
                            </el-pagination>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="已同意" name="1">
                            <div v-if="isShow">
                              <div class="message" v-for="(v,k) in companyList" :key="k">
                                <div>
                                    <div class="font">
                                        合作公司
                                    </div>
                                    <span>{{v.parentCompanyName}}</span>
                                </div>
                                <div>
                                    <div class="font">
                                        申请时间
                                    </div>
                                    <span>{{v.updateTime}}</span>
                                </div>
                                <div>
                                    <div class="font">
                                        授权状态
                                    </div>
                                    <span>{{v.state | state}}</span>
                                </div>
                                <div>
                                    <div class="button" @click="store(v.parentCompanyId)">查看店铺商品</div>
                                </div>
                            </div>
                            </div>
                            <div v-else>
                                <div class="company-box-2">
                                <div class="company-box-item" v-for="(v, k) of companyItem" :key="k">
                                    <div class="company-box-item-img">
                                    <img :src="v.companyLogo" alt="" />
                                    </div>
                                    <div class="company-box-item-name">
                                    <span>{{ v.companyName }}</span>
                                    </div>
                                    <div class="company-box-item-button">
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="goodlist-list" v-if="companyListItem[0]">
                            <div
                              class="goodlist-list-box"
                              v-for="(item, index8) in companyListItem"
                              :key="index8"
                            >
                              <div class="goodlist-list-box-box" @click="toMessage(item)">
                                <div class="goodlist-include-box">
                                  <img class="goodlist-list-img" :src="item.mainImage" alt="" />
                                </div>
                                <p class="goodlist-list-txt">{{ item.name }}</p>
                                <div class="price-row">
                                  <span class="price-a">￥</span>
                                  <span class="price-b">{{ item.price }}</span>
                                  <span class="price-a">.00</span>
                                </div>
                                <div class="goodlist-bottom">
                                  <div class="goodlist-bottom-left" @click.stop="toMessage(item)">
                                    <span>查看详情</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <el-card
                              class="box-card"
                              v-if="!isShow"
                              style="margin-bottom: 30px"
                              shadow="never"
                            >
                              <div class="text item">对不起,您所浏览的商品不存在。</div>
                            </el-card>
                          </div>
                          <div class="pagin-box-item" v-if="companyListItem[0]">
                            <el-pagination
                              background
                              layout="prev, pager, next"
                              :total="total"
                              :page-size="pageSize"
                              @current-change="itemhandleCurrentChange"
                              :hide-on-single-page="true"
                            >
                            </el-pagination>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="已拒绝" name="2">
                            <div v-if="isShow">
                              <div class="message" v-for="(v,k) in companyList" :key="k">
                                <div>
                                    <div class="font">
                                        合作公司
                                    </div>
                                    <span>{{v.parentCompanyName}}</span>
                                </div>
                                <div>
                                    <div class="font">
                                        申请时间
                                    </div>
                                    <span>{{v.updateTime}}</span>
                                </div>
                                <div>
                                    <div class="font">
                                        授权状态
                                    </div>
                                    <span>{{v.state | state}}</span>
                                </div>
                                <div>
                                    <div class="button" @click="store(v.parentCompanyId)">查看店铺商品</div>
                                </div>
                            </div>
                            </div>
                            <div v-else>
                                <div class="company-box-2">
                                <div class="company-box-item" v-for="(v, k) of companyItem" :key="k">
                                    <div class="company-box-item-img">
                                    <img :src="v.companyLogo" alt="" />
                                    </div>
                                    <div class="company-box-item-name">
                                    <span>{{ v.companyName }}</span>
                                    </div>
                                    <div class="company-box-item-button">
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="goodlist-list" v-if="companyListItem[0]">
                            <div
                              class="goodlist-list-box"
                              v-for="(item, index8) in companyListItem"
                              :key="index8"
                            >
                              <div class="goodlist-list-box-box" @click="toMessage(item)">
                                <div class="goodlist-include-box">
                                  <img class="goodlist-list-img" :src="item.mainImage" alt="" />
                                </div>
                                <p class="goodlist-list-txt">{{ item.name }}</p>
                                <div class="price-row">
                                  <span class="price-a">￥</span>
                                  <span class="price-b">{{ item.price }}</span>
                                  <span class="price-a">.00</span>
                                </div>
                                <div class="goodlist-bottom">
                                  <div class="goodlist-bottom-left" @click.stop="toMessage(item)">
                                    <span>查看详情</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <el-card
                              class="box-card"
                              v-if="!isShow"
                              style="margin-bottom: 30px"
                              shadow="never"
                            >
                              <div class="text item">对不起,您所浏览的商品不存在。</div>
                            </el-card>
                          </div>
                          <div class="pagin-box-item" v-if="companyListItem[0]">
                            <el-pagination
                              background
                              layout="prev, pager, next"
                              :total="total"
                              :page-size="pageSize"
                              @current-change="itemhandleCurrentChange"
                              :hide-on-single-page="true"
                            >
                            </el-pagination>
                          </div>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- <div class="message" v-for="(v,k) in companyList" :key="k">
                        <div>
                            <div class="font">
                                合作公司
                            </div>
                            <span>{{v.parentCompanyName}}</span>
                        </div>
                        <div>
                            <div class="font">
                                申请时间
                            </div>
                            <span>{{v.updateTime}}</span>
                        </div>
                        <div>
                            <div class="font">
                                授权状态
                            </div>
                            <span>{{v.state | state}}</span>
                        </div>
                        <div>
                            <div class="button" @click="store(v.parentCompanyId)">查看店铺商品</div>
                        </div>
                    </div> -->
                </div>
               <el-pagination v-if="companyListItem[0]"
                    background
                    layout=" prev, pager, next"
                    :page-size="pageSize"
                    @current-change="itemhandleCurrentChange"
                    :total="total"
                    :hide-on-single-page="true">
                </el-pagination>
           </div>
       </div>
   </div>
   
</template>
<script>

export default{
data(){
  return{
        companyList:[],
        companyLists:[],
        total:0,
        pageSize:10,
        pageNum:1,
        itemPageSize:8,
        itemPageNum:1,
        activeName:"0",
        state:0,
        isShow:true,
        // 点击详情显示一个商家信息
        companyItem: [{ id: "" }],
        itemid:"",
        itempaginationNum:"",
        companyListItem:[],
        companyId:sessionStorage.getItem("companyId")
  };
  
},
filters: {
    state(val) {
      if (val == 0) {
        return "待授权";
      } else if (val == 1) {
        return "已同意";
      } else if (val == 2) {
        return "已拒绝";
      }
    },
  },
  activeName(newVal) {
      console.log(newVal)
      if (newVal == 0) {
        this.cc();
      }
    },
methods:{
   async mandate(){
         const res = await this.$http({
            method: "get",
            url: `/front/authCompany/get?pageNum=${this.pageNum}&pageSize=${this.pageSize}`,
            params:{
                // id:"1", //String 根据主键查询时添加
                childCompanyId :this.companyId, //String 查看商铺下的申请时添加
                // childCompanyId:sessionStorage.getItem('companyId'), //String 查看申请了哪些商铺时添加
                state:this.state, //String 授权状态0.待授权1.已同意3.已拒绝
            }
      });
      console.log(res.data.data)
      this.companyList = res.data.data.list;
      this.total = res.data.total;
    },
    //同意
   async consent(id){
       const res = await this.$http({
            method: "post",
            url: `/front/authCompany/agree?id=${id}`
      });
      console.log(res.data.status)
      if(res.data.status == 200){
          this.$message({
                message:"授权成功",
                type: "success",
              });
      }else{
          return false;
      }
    },
    //拒绝
   async reject(id){
        const res = await this.$http({
            method: "post",
            url: `/front/authCompany/refuse?id=${id}`
      });
      if(res.data.status == 200){
          this.$message({
                message:"已拒绝申请",
                type: "success",
              });
      }else{
          return false;
      }
    },
    //查看店铺
   async store(id){
    this.itemid = id;
      this.isShow = false;
      const res = await this.$http({
        method: "get",
        url: `front/company/findList?id=${id}`,
      });
      this.companyItem = res.data.data.list;
      const data = await this.$http({
        method: "get",
        url: `front/product/findList?companyId=${id}&pageNum=${this.itemPageNum}&pageSize=${this.itemPageSize}`,
      });
      this.itempaginationNum = data.data.data.pages * this.pageSize;
      this.pageNum = 1;
      this.companyListItem = data.data.data.list;
      
      console.log(data.data.data);
    },
    // 去往详情页
    async toMessage(item) {
      const res = await this.$http({
        method: "get",
        url: "front/product/findById?id=" + item.id,
      });
      this.messageList = res.data.data;
      this.$router.push({
        path: "/goodMessage",
        query: { name: JSON.stringify(this.messageList) },
      });
    },
    handleCurrentChange(e){
        this.pageNum = e
        this.mandate();
    },
    tabClick(index) {
      console.log(index.name);
      this.state = index.name
      this.mandate();
    },
    itemhandleCurrentChange(num) {
      this.pageNum = num;
      this.store(this.itemid);
    },
},
created(){
    this.mandate();
},
}
</script>
<style lang='scss' scoped>
.box{
    margin-top: 180px;
    width: 100%;
}
.box_index{
    margin: 0 auto;
    width: 80%;
    
    margin-bottom: 50px;
}
.index_message{
    width: 80%;
    background: rgb(250, 249, 249);
    margin: 0 auto;
    
}
.message{
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid white;
    
}
 .message_A{
    margin: 10px 0;
}
.font{
    font-size: 15px;
}
.font_A{
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-top: 10px;
}
.button{
    background: #e4393c;
  color: #fff;
  padding: 8px 25px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  
}
.button1{
    background: #e4393c;
  color: #fff;
  padding: 8px 25px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 10px;
}
.company-box {
  margin-bottom: 50px;
}
.company-box-item {
  display: flex;
  background: url("../assets/images/bg-shop.png");
  margin: 20px 0;
}
.company-box-item-img {
  height: 100px;
  width: 180px;
  padding: 10px;
}
.company-box-item-img img {
  height: 100%;
  width: 100%;
}
.company-box-item-button span {
  background: #e4393c;
  color: #fff;
  padding: 8px 25px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120px;
  cursor: pointer;
  margin-right: 20px;
}
.company-box-item {
  display: flex;
  background: url("../assets/images/bg-shop.png");
  margin: 20px 0;
} 
.company-box-item-img {
  height: 100px;
  width: 180px;
  padding: 10px;
}
.company-box-item-img img {
  height: 100%;
  width: 100%;
}
.company-box-item-button span {
  background: #e4393c;
  color: #fff;
  padding: 8px 25px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120px;
  cursor: pointer;
  margin-right: 20px;
}
.company-box-item-name {
  width: 446px;
}
.company-box-item-name span {
  font-size: 14px;
  color: #333;
  line-height: 60px;
}
.goodlist-list {
  display: flex;
  /* flex: 1; */
  flex-wrap: wrap;
  width: 100%;
  min-width: 800px;
  /* height: 1500px; */
  margin: 0 auto;
  margin-top: 20px;
}
.goodlist-list-box {
  width: 23%;
  margin: 0 10px;
  min-width: 200px;
  /* height: 340px; */
}
.goodlist-list-box-box {
  width: 85%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 6px;
}
.goodlist-list-box-box:hover {
  box-shadow: darkgrey 0px 4px 19px 3px;
  /* height: 350px; */
}
.goodlist-include-box {
  width: 100%;
  height: 160px;
  margin: 0 auto;
  margin-top: 5%;
}
.goodlist-list-img {
  width: 100%;
  height: 180px;
  border-radius: 5px;
  /* margin-left: 22%;
    margin-top: 12%; */
}
.goodlist-list-txt {
  width: 90%;
  margin: auto;
  font-size: 13px;
  margin-top: 35px;
  margin-left: 15px;
}
.goodlist-list-txt:hover p {
  color: rgb(123, 123, 224);
}
.goodlist-include-box {
  width: 100%;
  height: 160px;
  margin: 0 auto;
  margin-top: 5%;
}
.goodlist-bottom {
  margin: 0 auto;
  display: flex;
  width: 70%;
  height: 50px;
  margin-top: 15px;
}
.goodlist-bottom:hover {
  border: 0.5px solid #df3033;
  border-radius: 4px;
}
.goodlist-bottom-left {
  border-radius: 4px;
  width: 1000%;
  height: 100%;
  background: #df3033;
  color: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.goodlist-bottom-left span {
  color: #fff;
}
.goodlist-bottom-left span {
  line-height: 50px;
}
.box-card {
  width: 75%;
  margin: 0 auto;
  text-align: center;
  height: 200px;
  margin-top: 20px;
}
.box-card div {
  color: #666;
  line-height: 150px;
}
.pagin-box-item {
  margin: 30px 0px 60px 700px;
}
</style>