<template>
     <div class="payList">
        
    <el-container id="el-container">
      <my-header></my-header>
      <div class="main-list">
        <div class="main">
          <div class="main_A" v-for="(item,index) in list" :key="index">
            <div class="main_AA">
              <div class="main_B">
                <span>{{item.region[0] + '-' + item.region[1]+"--"}}<span :style="{color:'red'}">{{item.cuisine}}厨师</span></span>
              </div>
              <div class="main_BB">
                <!-- <span>昵称:</span>
                <span>{{item.nickName}}</span> -->
                <span :style="{color:'blue'}">手机号:</span>
                <span >{{item.jobHuntingPhone}}</span>
              </div>
            </div>
            <div class="main_AA1">
              <div class="main_B">
                <span :style="{fontSize:'17px'}">期望薪资:</span>
                <span :style="{color:'#ff5959',fontSize:'16px'}"> {{item.expectedSalary}}/元</span>
              </div>
              <div class="main_BB">
                <!-- <span :style="{color:'blue'}">手机号:</span>
                <span >{{item.jobHuntingPhone}}</span> -->
              </div>
            </div>
            <div class="main_AA2">
              <div class="main_B">
                 <span>地区:</span>
                 <span>{{item.region[0] + '-' + item.region[1] + '-' + item.region[2]}}</span>
              </div>
              <div class="main_BB">
                <el-button type="primary" @click="clickAddsite(item.jobHuntingId,index)">查看详情</el-button>
              </div>
            </div>
          </div>
          <!-- <div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
          </div> -->
          <!-- <div class="first">
            <span>{{item.region[0] + '-' + item.region[1]+"--"+item.cuisine}}厨师</span>
            <el-button type="primary" @click="clickAddsite(item.jobHuntingId,index)">查看详情</el-button>
          </div>
          <hr>
          <div class="first1">
            <span>昵称:</span>
            <span>{{item.nickName}}</span>
          </div>
          <div class="first1">
            <span>手机号:</span>
            <span>{{item.jobHuntingPhone}}</span>
          </div>
          <div class="first1">
            <span>期望薪资:</span>
            <span>{{item.expectedSalary}}</span>
          </div>
          <div class="first1">
            <span>地区:</span>
            <span>{{item.region[0] + '-' + item.region[1] + '-' + item.region[2]}}</span>
          </div> -->
          
        </div>
         
      </div>
       
    </el-container>
    <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :total="total"
        :hide-on-single-page="true">
    </el-pagination>
    <el-dialog
    
      title="查看详情"
      :fullscreen="true"
      :visible.sync="dialogVisibleSpecification"
      class="message"
    >
      <div class="goods-message" >
        <div class="goods-message1" >
        <div>
          <span>昵称: </span>
          <span>{{lists.nickName}}</span>
        </div>
        <div>
          <span>个人介绍: </span>
          <span>{{lists.aboutme}}</span>
        </div>
        <div>
          <span>期望薪资: </span>
          <span>{{lists.expectedSalary}}/元</span>
        </div>
        <div>
          <span>工单状态: </span>
          <span>{{lists.state | state }}</span>
        </div>
        <div>
          <span>联系电话: </span>
          <span>{{lists.jobHuntingPhone}}</span>
        </div>
        <div>
          <span>地区: </span>
          <span>{{cityList[0]+'-'+cityList[1]+'-'+cityList[2]}}</span>
        </div>
        </div>
      </div>
    </el-dialog>
     </div>
</template>
<script>

export default{
data(){
  return{
      dialogVisibleSpecification:false,
      addsite:false,
      pageNum:1,
      pageSize:4,
      state:"",
      sortType:"create_time",
      list:[],
      lists:[],
      total:0,
      cityList:[]
  };
},
filters:{
			state(val){
				if(val==0){
					return '正常'
				}else{
					return '冻结'
				}
			},
		},
methods:{
  //根据id进入详情页
    async clickAddsite(id) {
      const res = await this.$http({
          method: "GET",
          url: `front/jobHuntingView/get?jobHuntingId=${id}`,
        });
        console.log()
        if (res.status == 200) {
          this.lists = res.data.data.list[0];
        }
      this.dialogVisibleSpecification = true;
      console.log("aa")
      this.cityList = this.lists.region
    console.log(this.cityList)
    },
    //渲染页面
    async wode(){
       const res = await this.$http({
          method: "GET",
          url: `/front/jobHuntingView/get?pageNume=${this.pageNum}&pageSize=${this.pageSize}`,
        });
        console.log(res.data.data.list)
        if (res.status == 200) {
         this.list = res.data.data.list
         this.total = res.data.total
        }
    },
    handleCurrentChange(e){
        this.pageNum = e
        this.wode();
    },
},
created(){
  this.wode();
},
}
</script>
<style lang='scss' scoped>
.payList {
  width: 100%;
  margin: 0 auto;
  background: #f4f6f9;
  
}
.header{
  font-size: 30px;
  margin-top: 150px;
  text-align: center;
  letter-spacing: 10px;
  font-weight: 100;
}
#el-container {
  /* background-color: #f4f4f4; */
  /* height: 8000px; */
  margin: 0;
  padding: 0;
}
.main-list{
  width: 50%;
  margin:0 auto;
}
.main{
    letter-spacing: 0;
    /* box-sizing: border-box; */
    padding: 0 15px;
    background: #fff;
  // background: #fff;
  // border: 1px solid rgb(216, 214, 214);
  // margin-bottom: 50px;
  
  // border-radius: 15px;
  // padding: 30px;
  // overflow: visible;
    margin-top: 200px;
   margin-bottom:30px;
   border-radius:10px;
}
.main_A{
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px 0 14px;
    border-bottom: 1px solid #eee;
    
}
.main_AA{
    font-size: 16px;
    height: 36px;
    margin-bottom: 2px;
    color: #333;
    line-height: 36px;
    
}
.main_AA1{
  line-height: 20px;
}
.main_B{
  display: inline-block;
  height:36px;
  padding: 0 15px;
}
.main_BB{
  height: 36px;
    line-height: 36px;
    clear: both;
    float: right;
    padding: 0 15px;
}
// .first {
//   display: flex;
//   justify-content: space-between;
//   padding: 10px 0;
//   text-align: center;
// }
// .first1{
//     padding: 10px 0;
// }
.el-pagination{
  text-align: center;

  margin: 0 auto;
}
.message{
  width: 51%;
  margin: 0 auto;
}
h1{
  text-align: center;
  font-size: 30px;
}
.goods-message1 div{
  padding: 8px 0;
margin-left: 20%;
}
.main{
  padding: 0 5px;
  
}
</style>