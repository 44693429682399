<template>
  <div>
    <div>
      <el-row class="title">
        <el-col :span="14">
          <div class="left">商品信息</div>
        </el-col>
        <el-col :span="10">
          <div class="right">
            <span>单价</span>
            <span>数量</span>
            <span>合计</span>
          </div>
        </el-col>
      </el-row>

      <div class="item-box">
        <div v-for="(v1, k) of orderListSon" :key="k">
          <el-row class="centertitle">

            <el-col :span="6">
              <div class="left">
                <span>订单号:</span>
                <span @click="lookDetail(v1)">{{ v1.id }}</span>
              </div>
            </el-col>

            <el-col :span="9">
              <div class="center">
                <div>
                  {{ v1.companyName }}
                </div>
                <div>
                  <span>订单状态:</span>
                  <span>{{ v1.state | state }}</span>
                </div>
                <div>
                  <span>订单总价:</span>
                  <span class="price">{{ `￥${v1.totalPrice}` }}</span>
                </div>
              </div>
            </el-col>

            <el-col :span="9">
              <div class="right">
                <span @click="lookDetail(v1)"
                  >查看详情<i class="el-icon-arrow-right"></i
                ></span>
              </div>
            </el-col>
            
          </el-row>

          <div v-for="(v, index) in orderListSon[k].orderItemVO" :key="index">
            <el-row class="item-box-item">
              <el-col :span="14">
                <div class="left">
                  <img :src="v.productImg" alt="" />
                  <span>{{ v.productName }}</span>
                </div>
              </el-col>
              <el-col :span="10">
                <div class="right">
                  <span>{{ `￥${v.totalPrice}` }}</span>
                  <div v-if="v.state == 50">
                    <span>
                      <button @click="subtract(v)">-</button>
                      {{ v.productNum }}
                      <button @click="add(v)">+</button>
                    </span>
                  </div>
                  <span>{{ `￥${v.totalPrice * v.productNum}` }}</span>
                </div>
              </el-col>
            </el-row>

            <div class="submit" v-if="v.state == 40">
              <el-popconfirm
                title="确定收货吗？"
                @confirm="takeOver(v.orderId)"
              >
                <el-button type="text" slot="reference">确认收货</el-button>
              </el-popconfirm>
            </div>

            <div class="submit" v-if="v.state == 50">
              <el-button
                type="text"
                slot="reference"
                @click="addReview(v, index)"
                >发布评价</el-button
              >
            </div>
          </div>

          <div class="submit" v-if="v1.state == 50">
            <el-button type="text" slot="reference" @click="again(v1, k)"
              >再来一单</el-button
            >
          </div>
        </div>
      </div>
      <el-pagination
        :style="{ marginLeft: '600px', padding: '20px' }"
        class="el-pagination"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="total"
        @current-change="currentchange1"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderListSon", "total"],
  inject: ["reload"],
  data() {
    return {
      pageNum: 1,
      pageSize: 5,
      cartNote: [{}],
      list: [],
      parameter: [
        {
          productId: "",
          productParamId: "",
          count: "",
        },
      ],
      count: "",
    };
  },
  methods: {
    // 发布评价
    addReview(v, index) {
      // for(let i =1 ;i<=v.orderItemVO.length;i++){
      //   console.log(i)
      // }
      let aa = v;
      console.log(v);
      this.$router.push({
        path: "/addReview",
        query: {
          item: aa,
        },
      });
    },
    //再来一单
    async again(v1, index) {
      let arr = v1.orderItemVO;
      console.log("+++++++++++++++++++++", arr);
      for (let i = 0; i < arr.length; i++) {
        console.log(1);
        //参数为JSON类型进行转换
        if (!this.parameter[i]) {
          this.parameter.push({
            productId: "",
            productParamId: "",
            count: "",
          });
        }
        this.parameter[i].productId = arr[i].productId;
        this.parameter[i].productParamId = arr[i].productParamId;
        this.parameter[i].count = arr[i].productNum;
        console.log(this.parameter[i]);
      }
      console.log(this.parameter);
      JSON.stringify(this.parameter);
      const res = await this.$http({
        method: "post",
        url: "front/cart/addNoteList",
        data: {
          cartNote: JSON.stringify(this.parameter),
        },
      });
      console.log(res.data);
      if (res.status == 200) {
        this.$router.push("shoppingList");
      }
    },
    //增加
    add(v) {
      this.count = v.productNum;
      console.log(this.count);
      v.productNum++;
    },
    //减少
    subtract(v) {
      if (v.productNum <= 0) {
        v.productNum = 0;
      } else {
        v.productNum -= 1;
      }
    },
    // 页面改变时的回调函数
    currentchange1(p) {
      this.pageNum = p;
      this.$emit("getOrderListSon", this.pageNum, this.pageSize);
    },
    //   查看详情
    lookDetail(item) {
      this.$router.push({
        path: "/orderListDetails",
        query: {
          item: JSON.stringify(item),
        },
      });
    },
    // 确认收货
    async takeOver(v) {
      console.log(v);
      const res = await this.$http({
        method: "post",
        url: "front/order/updateOrder",
        data: {
          outTradeNo: v,
          orderState: "50",
        },
      });
      if (res.data.status == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.reload();
        this.$emit("updateActive", 40);
      }
    },

    //  const res = await this.$http({
    //     method: "post",
    //     url: "front/order/updateOrder",
    //     data: {
    //       outTradeNo: v.id,
    //       orderState:'50',
    //     },
    //   });
  },
  filters: {
    state(val) {
      if (val == 0) {
        return "已取消";
      } else if (val == 10) {
        return "未支付";
      } else if (val == 20) {
        return "已付款";
      } else if (val == 40) {
        return "已发货";
      } else if (val == 50) {
        return "已完成";
      } else if (val == 60) {
        return "已关闭";
      }
    },
  },
  created() {},
  mounted() {
    console.log(this.orderListSon);
    console.log(this.total);
  },
};
</script>

<style lang="scss" scoped>
* {
  font: 12px/1.5 tahoma, arial, Hiragino Sans GB, \\5b8b\4f53, sans-serif;
}
.el-row > div:not(:nth-child(1)) {
  margin: 0;
}

.submit {
  text-align: right;
  & ::v-deep .el-button {
    padding: 0;
    span {
      display: inline-block;
      height: 25px;
      line-height: 25px;
      font-size: 12px;
      padding: 2px 10px;
      margin-top: 5px;
      margin-right: 10px;
      background-color: #c60023;
      color: #fff !important;
      cursor: pointer;
    }
  }
}
.title {
  background: #eeeeee;
  color: #666;
  .left {
    margin-left: 150px;
    line-height: 45px;
  }
  .right {
    height: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    span {
      height: 100%;
      line-height: 45px;
    }
  }
}
.item-box {
  .centertitle {
    padding: 10px;
    background: #eeeeee;
    margin-top: 20px;
    .left {
      color: #999;
      & > span:nth-child(2) {
        font-weight: 700;
        cursor: pointer;
      }
      & > span:nth-child(2):hover {
        color: #e80012;
      }
    }
    .center {
      color: #999;
      display: flex;
      justify-content: space-around;
      .price {
        font-weight: 700;
        color: #c60023;
      }
    }
    .right {
      color: #999;
      text-align: right;
      & span {
        font-weight: 700;
        cursor: pointer;
        i {
          font-weight: 700;
        }
        &:hover {
          color: #e80012;
        }
      }
    }
  }
}
.item-box-item {
  border-bottom: 1px solid #eee;
  height: 100px;
  .left {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      margin: 9px;
      margin-left: 30px;
      border: 1px solid #ddd;
    }
    span {
      margin-left: 15px;
      color: #999;
    }
  }
  .right {
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
</style>
