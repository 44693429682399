<template>
    <div class="border_box">
        <my-header></my-header>
        <div class="box">
            <div class="boxx">发布工单</div>
            <el-form label-width="200px" >
                <el-form-item label="工种类型:" prop="orderType" >
                    <el-select placeholder="请选择工种类型" v-model="orderType" @change="select(orderType)">
                        <el-option 
                        v-for="(item,index) in options"
                        :key="index"
                        :label="item.name"
                        :value="index"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称:" prop="projectName">
                    <el-input placeholder="项目名称" v-model="projectName"></el-input>
                </el-form-item>
                <el-form-item label="工资标准:" prop="payLevel">
                    <el-input placeholder="不填写默认面议" v-model="payLevel"></el-input>
                </el-form-item>
                <el-form-item label="所需人数:" prop="peopleNum">
                    <el-input placeholder="所需人数" v-model="peopleNum"></el-input>
                </el-form-item>
                <el-form-item label="预计天数:" prop="dayNum">
                    <el-input placeholder="预计天数" v-model="dayNum"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:" prop="linkPhone">
                    <el-input placeholder="请输入联系电话" v-model="linkPhone"></el-input>
                </el-form-item>
                <el-form-item
                    label="工单所在城市"
                    prop="receiverProvince"
                    v-if="receiverProvince.length"
                >
                    <el-cascader
                    v-model="receiverProvinces"
                    :options="allAreaData"
                    @change="handleChange1"
                ></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址:" prop="address">
                    <el-input placeholder="请输入详细地址" v-model="address"></el-input>
                </el-form-item>
                <el-form-item label="预计开工时间:" prop="workTime" >
                    <el-date-picker
                        value-format="yyyy-MM-dd"
                        @change="workTimes"
                        v-model="workTime"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                
                <div v-if="this.subscript == 2">
                    <el-form-item label="计价方式:" >
                        <el-radio v-model="status" label="1">点工</el-radio>
                        <el-radio v-model="status" label="2">包工</el-radio>
                    </el-form-item>
                    <el-form-item label="工资是否可议:">
                        <el-radio v-model="statuss" label="1">可议</el-radio>
                        <el-radio v-model="statuss" label="2">不可议</el-radio>
                  </el-form-item>
                    <el-form-item label="用人类型:">
                        <el-radio v-model="statusss" label="1">长工</el-radio>
                        <el-radio v-model="statusss" label="2">短工</el-radio>
                    </el-form-item>
                    <el-form-item label="工单类型:">
                        <el-radio v-model="statussss" label="1">散工</el-radio>
                        <el-radio v-model="statussss" label="2">队伍</el-radio>
                    </el-form-item>
                </div>
                <div v-else>
                  <el-form-item label="工资是否可议:">
                        <el-radio v-model="statuss" label="1">可议</el-radio>
                        <el-radio v-model="statuss" label="2">不可议</el-radio>
                  </el-form-item>
                </div>
                <el-form-item label="说明:" prop="workExplain">
                    <el-input placeholder="输入说明" v-model="workExplain" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="现场图:">
                    <el-upload 
                        class="upload-demo"
                        :action="uploadUrl"
                        :on-remove="logohandleRemove"
                        :on-success="logohandleSuccess"
                        :on-exceed="logohandleonExceed"
                        :file-list="fileList"
                        :limit="2"
                        name="upload_file"
                        list-type="picture-card"
                    >
                    <i class="el-icon-plus"></i>
                </el-upload>
                </el-form-item>
                <el-form-item> 
                    <el-button type="primary" @click="save">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>

export default{
data(){
  return{
      options:[],
      orderType:"",//工种类型
      projectName:"",//项目名称
      payLevel:"",//工资标准
      peopleNum:"",//所需人数
      dayNum:"",//预计天数
      linkPhone:"",//联系电话
      receiverProvince:[],
      receiverProvinces:"",//所在城市
      provinceId:"",//所在城市/省
      cityId:"",//所在城市/市
      countyId:"",//所在城市/县/区
      address:"",//详细地址
      workTime:"",//开工时间
      workExplain:"",//输入说明
      allAreaData:[],
      status:"",//计价方式
      statuss:"",//工资是否可议
      statusss:"",//用人类型
      statussss:"",//工单类型
      uploadUrl: "http://www.jujiangmofang.cn/kitchenac/file/upload",
      fileList: [],
      idCardImg:[],
      subscript:'',//下标
      huahua:[],
      workId:"",//工单id
      workName:"",//工单名称
      isShow:true,
  };
},

methods:{
    async wode(){
        const res = await this.$http({
          method: "GET",
          url: `front/workType/getTypeList`,
        });
        console.log(res.data.data)
        if (res.status == 200) {
          this.options = res.data.data;
          let that = this;
          that.huahua = res.data.data[that.subscript];
          that.workId = that.huahua.id;
          that.workName = that.huahua.name;
          console.log(that.workId,"+===============",that.workName)
        }
    },
    async getUserId() {
      const res = await this.$http({
        method: "get",
        url: "front/user/getUserByToken",
      });
      if (res.data.data) {
        sessionStorage.setItem("userId", res.data.data.id);
       this.allAreaData = JSON.parse(sessionStorage.getItem("allAreaData"));
        let provinceData = {};
        let cityData = {};
        let districtData = {};
        this.allAreaData.forEach((item, index) => {
          if (item.label == res.data.data.region[0]) {
            provinceData = item;
          }
        });
        provinceData.children.forEach((item, index) => {
          if (item.label == res.data.data.region[1]) {
            cityData = item;
          }
        });
        cityData.children.forEach((item, index) => {
          if (item.label == res.data.data.region[2]) {
            districtData = item;
          }
        });
        this.receiverProvince.push(provinceData.value);
        this.receiverProvince.push(cityData.value);
        this.receiverProvince.push(districtData.value);
      }
    },
    handleChange1(value) {
      console.log(value[0],value[1],value[2]);
      this.provinceId = value[0];
      this.cityId = value[1];
      this.countyId = value[2];
    },
    logohandleRemove(file, fileList) {
      console.log(file, fileList);
      this.idCardImg.forEach((v, k) => {
        if (v.img == `${file.response.data.url}${file.response.data.uri}`) {
          this.idCardImg.splice(k, 1);
        }
      });
      console.log(this.idCardImg);
    },
    logohandleSuccess(k, v, list) {
      this.idCardImg.push({ img: `${k.data.url}${k.data.uri}` });
      console.log(this.idCardImg);
    },
    logohandleonExceed() {
      this.$message({
        message: "无法继续上传,上传图片个数超出限制。",
        type: "error",
      });
    },
    select(e){
      console.log(e)
      this.subscript = e;
      this.wode();
    },
    workTimes(time){
      console.log(time)
      this.workTime = time;
    },
    //发布工单
    async save(){
      if(this.subscript == 2){
        const res = await this.$http({
            method:'post',
            url:`/front/workOrder/save`,
            data:{
              projectName:this.projectName,//项目名称
              payLevel:this.payLevel,//工资标准
              peopleNum:this.peopleNum,//所需人数
              dayNum:this.dayNum,//预计天数
              linkPhone:this.linkPhone,//联系电话
              provinceId:this.provinceId,//所在城市/省
              cityId: this.cityId,//所在城市/市
              countyId:this.countyId,//所在城市/县/区
              address:this.address,//详细地址
              workTime:this.workTime,//开工时间
              workExplain:this.workExplain,//输入说明
              status:this.status,//计价方式
              statuss:this.statuss,//工资是否可议
              statusss:this.statusss,//用人类型
              statussss:this.statussss,//工单类型
              workId:this.workId,//工单id
              workName:this.workName,//工单名称
              idCardImg:this.idCardImg,//现场图
            }
        });
        if(res.data.status == 200){
          this.$message({
                message: "发布工单成功",
                type: "success",
                });
        }
        this.$router.push({path:'/searchTotal'})
      }else{
        const res = await this.$http({
            method:'post',
            url:`/front/workOrder/save`,
            data:{
              projectName:this.projectName,//项目名称
              payLevel:this.payLevel,//工资标准
              peopleNum:this.peopleNum,//所需人数
              dayNum:this.dayNum,//预计天数
              linkPhone:this.linkPhone,//联系电话
              provinceId:this.provinceId,//所在城市/省
              cityId: this.cityId,//所在城市/市
              countyId:this.countyId,//所在城市/县/区
              address:this.address,//详细地址
              workTime:this.workTime,//开工时间
              workExplain:this.workExplain,//输入说明
              // status:this.status,//计价方式
              statuss:this.statuss,//工资是否可议
              // statusss:this.statusss,//用人类型
              // statussss:this.statussss,//工单类型
              workId:this.workId,//工单id
              workName:this.workName,//工单名称
              idCardImg:this.idCardImg,//现场图
            }
        });
        if(res.data.status == 200){
          this.$message({
                message: "发布工单成功",
                type: "success",
                });
        }
        this.$router.push({path:'/searchTotal'})
      }
    }
},
created(){
    this.wode();
    this.getUserId();
},
}
</script>
<style lang='scss' scoped>
.border_box{
    width: 100%;
    min-width: 1500px;
    margin: 0 auto;
    background: #f4f4f4;
    margin-top: 160px;
}
.box{
    width: 50%;
    border: 1px solid rgb(236, 233, 233);
    margin: auto;
    background: #fff;
}
.box .boxx{
    font-size: 30px;
    letter-spacing: 10px;
    text-align: center;
    margin-bottom: 20px;
}
.box .el-input{
    width: 500px;
}
</style>