<template>
  <div class="addReview">
    <my-header></my-header>
    <div class="box">
      <div class="title">
        <h4>发布评价</h4>
        <span>快乐的你分享完美的使用心得~</span>
      </div>
      <div class="center">
        <div class="center-left">
          <img :src="item.productImg" alt="" />
          <p>{{ item.productName }}</p>
          <p>{{ item.companyName }}</p>
          <p class="price">{{ `￥${item.totalPrice}` }}</p>
        </div>
        <div class="center-right">
          <div>
            <span>评分：</span>
            <div>
              <el-rate
                v-model="score"
                allow-half
                show-score
                text-color="#ff9900"
                score-template="{value}"
                :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
              >
              </el-rate>
              <span>{{ evaluate | evaluate }}</span>
            </div>
          </div>
          <div>
            <p>评价内容：</p>
            <el-input
              type="textarea"
              placeholder="写写你的感受吧，一不小心就成了评论高手。"
              v-model="matter"
              maxlength="500"
              show-word-limit
              :autosize="{ minRows: 3, maxRows: 8 }"
              rows="2"
              :readonly="false"
            >
            </el-input>
          </div>
          <upload
            :length="'3'"
            :height="'80px'"
            :width="'80px'"
            :token="'507f056af74db9e9bdff3d4258e4f5593f6f1c1b:NRIYPJqlugY0DjVS702JPs9ecCg=:eyJkZWFkbGluZSI6MTYxNTE5NTY2NCwiYWN0aW9uIjoiZ2V0IiwidWlkIjoiNjcxNTQxIiwiYWlkIjoiMTc0OTkyNyIsImZyb20iOiJmaWxlIn0='"
            @getImg="getImg"
          ></upload>
        </div>
      </div>
      <div class="bottom">
        <span @click="setEvaluate">发表</span>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "../components/upload/upload";
export default {
  inject: ["reload"],
  components: { upload },
  data() {
    return {
      //   分数
      score: 5,
      // 商品详情
      item: {},
      //评价内容
      matter: "",
      // 图片列表
      imgs: [],
    };
  },
  methods: {
    //   获取url传递的商品详情
    getItem() {
      this.item = this.$route.query.item;
      console.log(this.item);
      
    },
    // 获取上传组件的已上传图片
    getImg(imgs) {
      this.imgs = imgs;
      console.log(imgs);
    },
    // 发表评价
    async setEvaluate() {
      console.log(
        "分数" + this.score,
        "好评" + this.evaluate,
        "图片" + JSON.stringify(this.imgs),
        "内容" + this.matter
      );
      const res = await this.$http({
        method: "post",
        url: "front/productEvaluate/addReview",
        data: {
          type: this.evaluate,
          star: this.score,
          imgUrl: JSON.stringify(this.imgs),
          matter: this.matter,
          orderItemId: this.item.id,
        },
      });
      console.log(res.data);
      if(res.data.status == 200){
        this.$message({
          message: "评论成功",
          type: "success",
        });
      }
    },
  },
  computed: {
    // 根据评价分数判断好评差评
    evaluate() {
      let val = "";
      if (this.score <= 2) {
        val = 3;
      } else if (this.score > 2 && this.score <= 3.5) {
        val = 2;
      } else if (this.score > 3.5) {
        val = 1;
      }
      return val;
    },
  },
  filters: {
    evaluate(val) {
      if (val == 1) {
        return "好评";
      } else if (val == 2) {
        return "中评";
      } else if (val == 3) {
        return "差评";
      }
    },
  },
  created() {
    this.getItem();
  },
};
</script>

<style lang="scss" scoped>
.addReview {
  background: #f5f5f5;
  padding-bottom: 40px;
}
.box {
  margin-top: 140px;
  .title {
    padding: 15px;
    text-align: center;
    h4 {
      font: 700 16px/26px "Microsoft YaHei";
      color: #333;
    }
    span {
      color: #666;
      font: 12px/22px verdana;
    }
  }
  .center {
    width: 1250px;
    margin: 0 auto;
    margin-bottom: 10px;
    background: #fff;
    display: flex;
    .center-left {
      flex: 4;
      border-right: 1px solid #eee;
      display: flex;
      flex-direction: column;
      text-align: center;
      img {
        margin: 0 auto;
        width: 100px;
        height: 100px;
        margin-top: 50px;
        margin-bottom: 15px;
        border: 1px solid #eee;
      }
      p {
        color: #666;
        line-height: 20px;
        font: 12px/150% tahoma, arial, Microsoft YaHei, Hiragino Sans GB,
          "\u5b8b\u4f53", sans-serif;
      }
      .price {
        margin-top: 20px;
        font-family: verdana;
        font-weight: bold;
      }
    }
    .center-right {
      padding: 20px 80px;
      flex: 11;
      & > div:nth-child(1) {
        span {
          display: inline-block;
          line-height: 20px;
          margin-bottom: 10px;
          color: #333;
          font-size: 16px;
        }

        & > div:nth-child(2) {
          display: flex;
          span {
            line-height: 20px;
            margin-left: 10px;
            margin-bottom: 10px;
            color: #888;
            font-size: 13px;
          }
        }
      }
      & > div:nth-child(2) {
        margin-top: 10px;
        p {
          display: inline-block;
          line-height: 20px;
          margin-bottom: 10px;
          color: #333;
          font-size: 16px;
        }
      }
    }
  }
  .bottom {
    width: 1250px;
    margin: 0 auto;
    margin-top: 10px;
    height: 115px;
    background: #fff;
    text-align: center;

    span {
      width: 220px;
      height: 48px;
      line-height: 48px;
      padding: 0;
      font-family: "Microsoft YaHei";
      font-size: 18px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      color: #fff;
      border-radius: 3px;
      background-color: #df3033;
      background-image: linear-gradient(to bottom, #df3033 0, #e74649 100%);
      margin-top: 30px;
    }
  }
}
</style>