<template>
  <div>
    <my-header></my-header>
    <div class="box">
      <div>
        <span>购物清单</span>
        <span  class="lookgoodListInfo"  @click="lookgoodListInfo"
          >查询购物清单</span
        >
      </div>
      <div class="box-form">
        <el-form
          :model="ruleForm"
          :rules="rules"
           ref="ruleForm"
          label-width="180px"
        >
         
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="预算" prop="budget">
            <el-input v-model="ruleForm.budget"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="message">
            <el-input
              type="textarea"
              v-model="ruleForm.message"
            ></el-input>
          </el-form-item>
          <el-form-item label="购物清单附件">
            <el-upload
              class="upload-demo"
              action="http://www.jujiangmofang.cn/kitchenac/file/upload"
              :on-change="handleChange"
              :file-list="fileList"
              :on-progress="uploadprogress"
              :on-success="onSuccess"
              :on-remove="onRemove"
              name="upload_file"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
                v-text="progress ? '上传中,请稍后...' : '请上传备注附件。'"
              ></div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
            
              >提交</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      // 已提交业务信息
      // Submittedbusiness: [],
      // 提示上传中显示隐藏
      progress: false,
      fileList: [],
      // 地区
      options: JSON.parse(sessionStorage.getItem("allAreaData")),
      // 情报查重列表显示隐藏
      dialogVisible: false,
      // 确认提交状态
      affirmSubmit: false,
     
      ruleForm: {
        name:'',
        phone:'',
        affix:'',  
        budget :'',
        message: '',
        
      },
      rules:{
        name:[{ required: true, message: "您的姓名",trigger: "blur"}],
        phone:[{ required: true, message: "您的联系方式",trigger: "blur"}],
        budget:[{ required: true, message: "您的预算",trigger: "blur"}],
        affix:[{ required: true, message: "请上传附件",trigger: "blur"}],
      },
      // 记录当前状态是否为重新提交
      resubmit: false,
    };
  },
  created() {
    this.getquery();
  },
  mounted(){

  },
  methods: {
  
    // // 获取路由传参
    getquery() {
      console.log(this.$route.query);
      if (this.$route.query.hasOwnProperty("item")) {
        // 如果上传的有备注附件，将备注附件添加到fileList列表
        if (JSON.parse(this.ruleForm.message).length) {
          this.fileList = JSON.parse(this.ruleForm.message);
        }

        console.log(this.ruleForm);
      }
    },
    // 文件删除时的钩子
    onRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
    // 文件上传成功时的钩子
    onSuccess(response, file, fileList) {
      this.progress = false;
      this.fileList = fileList;
      this.ruleForm.affix =fileList[0].response.data.url + fileList[0].response.data.uri;
    },
    // 文件上传时的钩子
    uploadprogress(event, file, fileList) {
      this.progress = true;
    },
    handleChange(file, fileList) {
      // console.log(file);
      // console.log(fileList);
      // console.log(this.fileList);
    },
    // 确认提交情报
    affirm() {
      this.affirmSubmit = true;
      this.submitForm("ruleForm");
    },
    
    async submitForm(ruleForm) {

        if (this.ruleForm.name!=='' && this.ruleForm.phone!=='' && this.ruleForm.affix!=='' &&
     this.ruleForm.budget!=='') {
       // 向后台发送请求
        const res = await this.$http({
        method:"post",
        url: "front/shoppingList/insert",
        data:{
          name:this.ruleForm.name,
          phone :this.ruleForm.phone ,
          affix :this.ruleForm.affix ,
          budget:this.ruleForm.budget,
          message:this.ruleForm.message,
          userId: sessionStorage.getItem("userId")
        }
        
      })
      alert(`提交成功!`)
      this.$router.push('/lookgoodListInfo')
      this.ruleForm=''
        } else {
          //就向用户提示发生错误的消息
          this.$message('请完整填写信息!')
      }
      
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
      // 查看购物清单
   lookgoodListInfo(){
     this.$router.push('./lookgoodListInfo')
    },
  },
  
  
    
}
 
 

</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  margin-top: 150px;
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
    & > .lookgoodListInfo {
      position: absolute;
      right: 150px;
      top: 50px;
      height: 30px;
      color: #5079d9;
      line-height: 0px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .box-form {
    padding: 20px;
    width: 70%;
    margin: 0 auto;
  }
  & ::v-deep .el-progress__text {
    display: none;
  }
}
</style>