<template>
     <div class="payList">
        <div class="header">我发布的</div>
    <el-container id="el-container">
      <my-header></my-header>
      <el-main class="main-list"  v-for="(item,index) in list" :key="index">
        <div class="main">
          <div class="first">
            <span>{{item.projectName}}</span>
            <span @click="clickAddsite(item.id)">查看详情</span>
          </div>
          <hr>
          <div class="first1">
            <span>工资:</span>
            <span>{{item.payLevel}}</span>
          </div>
          <div class="first1">
            <span>地址:</span>
            <span>{{item.workArea[0] + '-' + item.workArea[1] + '-' + item.workArea[2] + ' ' + item.address}}</span>
          </div>
          <div class="first1">
            <span>状态:</span>
            <span>{{item.state | state}}</span>
          </div>
          <div class="first1">
            <span>工单类型:</span>
            <span>{{item.workName}}</span>
          </div>
          <div class="first">
            <span>所需人数:{{item.peopleNum}}</span>
            <span>报名人数:{{item.applyNum}}</span>
          </div>
          <hr>
          <div class="first">
            <span>{{item.createTime}}</span>
            <el-button type="primary" plain @click="repairOrder(item.id,index)">完结工单</el-button>
          </div>
        </div>
         
      </el-main>
       
    </el-container>
    <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :total="total"
        :hide-on-single-page="true">
    </el-pagination>
    <el-dialog
    
      title="查看详情"
      :fullscreen="true"
      :visible.sync="dialogVisibleSpecification"
      class="message"
      
    >
      <div class="goods-message" >
        <div class="goods-message1" >
           <h1>{{lists.projectName}}</h1>
        <div>
          <span>工种名称: </span>
          <span>{{lists.workName}}</span>
        </div>
        <div>
          <span>工单类型: </span>
          <span>{{lists.workOrderType==1?'散工':'队伍'}}</span>
        </div>
        <div>
          <span>用人类型: </span>
          <span>{{lists.employmentType==1?'长工':'短工'}}</span>
        </div>
        <div>
          <span>计价方式: </span>
          <span>{{lists.priceType==1?'点工':'包工'}}</span>
        </div>
        <div>
          <span>地点: </span>
          <span>{{ lists.workArea + ' ' + lists.address}}</span>
        </div>
        <div>
          <span>工资标准: </span>
          <span>{{lists.payLevel}}</span>
        </div>
        <div>
          <span>预计天数: </span>
          <span>{{lists.dayNum}}天</span>
        </div>
        <div>
          <span>是否可议: </span>
          <span>{{lists.isTalk==1?'不可议':'可议'}}</span>
        </div>
        <div>
          <span>开工时间: </span>
          <span>{{lists.workTime}}</span>
        </div>
        <div>
          <span>施工说明: </span>
          <span>{{lists.workExplain}}</span>
        </div>
        <div>
          <span>工单状态: </span>
          <span>{{lists.state | state }}</span>
        </div>
        <div>
          <span>所需人数: </span>
          <span>{{lists.peopleNum}}</span>
        </div>
        <div>
          <span>报名人数: </span>
          <span>{{lists.applyNum}}</span>
        </div>
        <div>
          <span>联系电话: </span>
          <span>{{lists.linkPhone}}</span>
        </div>
        <div>
          <span>现场图: </span>
          <span>
            <img :src="lists.spotImg" >
          </span>
        </div>
        </div>
      </div>
    </el-dialog>
     </div>
</template>
<script>

export default{
data(){
  return{
      dialogVisibleSpecification:false,
      addsite:false,
      pageNum:1,
      pageSize:4,
      state:"1",
      sortType:"create_time",
      list:[],
      lists:[],
      total:0,
  };
},
filters:{
			state(val){
				if(val==1){
					return '正常招工'
				}else if(val == 2){
					return '已完结'
				}else{
					return '已封禁'
				}
			},
		},
methods:{
  //根据id进入详情页
    async clickAddsite(id) {
      const res = await this.$http({
          method: "GET",
          url: `front/workOrder/selectById?id=${id}`,
        });
        console.log(res.data.data)
        if (res.status == 200) {
          this.lists = res.data.data
        }
      this.dialogVisibleSpecification = true;
      console.log(this.lists)
    },
    //渲染页面
    async wode(){
       const res = await this.$http({
          method: "GET",
          url: `front/workOrder/findByUserId?pageNum=${this.pageNum}&pageSize=${this.pageSize}&state=${this.state}&sortType=${this.sortType}`,
        });
        console.log(res.data.data.list)
        if (res.status == 200) {
          this.list = res.data.data.list
          this.total = res.data.data.total
        }
    },
    handleCurrentChange(e){
        this.pageNum = e
        this.wode();
    },
    //完结工单
     repairOrder(id,i){
        this.$confirm('确定要完结本工单么?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(async() => {
          const res = await this.$http({
          method: "post",
          url: `front/workOrder/updateWorkOrderStatus?workOrderId=${id}&state=2`,
        });
          if(res.data == 200){
            this.list.splice(i,1);
            this.$message({
              type: 'success',
              message: '完结成功!'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消完结'
          });          
        });
    }
},
created(){
  this.wode();
},
}
</script>
<style lang='scss' scoped>
.payList {
  width: 80%;
  margin: 0 auto;
  
}
.header{
  font-size: 30px;
  margin-top: 150px;
  text-align: center;
  letter-spacing: 10px;
  font-weight: 100;
}
#el-container {
  /* background-color: #f4f4f4; */
  /* height: 8000px; */
  margin: 0;
  padding: 0;
}
.main-list {
  width: 100%;
  background: #fff;
  border: 1px solid rgb(216, 214, 214);
  margin-bottom: 50px;
  margin-top: 50px;
  border-radius: 15px;
  padding: 30px;
  overflow: visible;
 
  
}

.first {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  text-align: center;
}
.first1{
    padding: 10px 0;
}
.el-pagination{
  text-align: center;

  margin: 0 auto;
}
.message{
  width: 51%;
  margin: 0 auto;
}
h1{
  text-align: center;
  font-size: 30px;
}
.goods-message1 div{
  padding: 8px 0;
margin-left: 20%;
}
.main{
  padding: 0 5px;
  
}
</style>